export const KEYS = {
  LEFT: 37,
  UP: 38,
  RIGHT: 39,
  DOWN: 40,
  ENTER: 13,
  SPACE: 32
};

interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
